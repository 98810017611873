import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDays,
  faHeart,
  faQuoteRight,
  faQuoteLeft
} from '@fortawesome/free-solid-svg-icons'
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons'
import Layout from './Layout'
import Seo from './Seo'
import BackToTopButton from './BackToTopButton'
import Link from './LinkWrapper'

const InspirationalChallengeQuery = graphql`
  query InspirationalChallengeQuery {
    survival: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הישרדות-ענקית-מרחפת-בגובה-מטר-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const InspirationalChallengeTemplate = ({ pathname, search, data, images }) => {
  const [iframeUrl, setIframeUrl] = useState(null)
  useEffect(() => {
    setIframeUrl(
      `https://www.karinnarts.co.il/wp/?ff_landing=10&embedded=1&challenge_name=${encodeURIComponent(
        data.challenge.name.replaceAll(' ', '-')
      )}` +
        (search
          ? '&' +
            search
              .slice(1)
              .split('&')
              .filter(
                (item) =>
                  item.split('=')[0] !== 'ns_url' &&
                  item.split('=')[0] !== 'mid'
              )
              .join('&')
          : '')
    )
  }, [])
  const { survival } = images

  return (
    <>
      <BackToTopButton selectorId='challenge' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{data.headerAcademy}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {data.header}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column'>
                <h3 className='is-size-5 has-text-nude'>
                  {data.challenge.subtitle}
                </h3>
                <h1 className='is-size-2 is-family-third is-margin-top-6 is-margin-bottom-6'>
                  {data.challenge.title}
                </h1>
                <div className='has-text-centered'>
                  <a
                    href={`${pathname}#registration`}
                    className='button is-nude is-medium'
                  >
                    {data.callToAction}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.challenge.bullets.map((bullet, index) => (
        <div
          id={index === 1 ? 'challenge' : undefined}
          key={index}
          className={clsx('hero', {})}
        >
          <div className='hero-body'>
            <div className='container'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  <GatsbyImage
                    image={getImage(data.images[index])}
                    title={bullet.image.title}
                    alt={bullet.image.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(data.images[index]).width,
                      margin: '0 auto' // used to center the image
                    }}
                    loading='eager'
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {bullet.image.title}
                    </p>
                  </div>
                </div>
                <div
                  className={clsx(
                    'column is-full-mobile is-10-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 0
                    }
                  )}
                >
                  <div className='content'>
                    <h3>{bullet.title}</h3>
                    <p>{bullet.description}</p>
                    <strong>{bullet.question}</strong>
                  </div>
                </div>
              </div>
              {/* {index === 0 && (
                <div className='has-text-centered is-margin-top-6'>
                  <a
                    href={`${pathname}#registration`}
                    className='button is-nude is-medium'
                  >
                    {data.callToAction}
                  </a>
                </div>
              )} */}
            </div>
          </div>
        </div>
      ))}
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='has-text-centered'>
              <a
                href={`${pathname}#registration`}
                className='button is-nude is-medium'
              >
                {data.callToAction}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <FontAwesomeIcon
              icon={faCalendarDays}
              className='has-text-nude is-margin-bottom-4'
              size='lg'
            />
            <div className='is-margin-bottom-4'>
              <h3>{data.challenge.when.title}</h3>
            </div>
            <p>{data.challenge.when.description}</p>
          </div>
        </div>
      </div>
      {data.challenge.who && (
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <FontAwesomeIcon
                icon={faFaceSmile}
                className='has-text-nude is-margin-bottom-4'
                size='lg'
              />
              <div className='is-margin-bottom-4'>
                <h3>{data.challenge.who.title}</h3>
              </div>
              <p>{data.challenge.who.description}</p>
            </div>
          </div>
        </div>
      )}
      {iframeUrl && (
        <div id='registration' className='hero'>
          <div className='hero-body is-padding-bottom-0'>
            <div className='container content has-text-centered'>
              <FontAwesomeIcon
                icon={faHeart}
                className='has-text-nude is-margin-bottom-4'
                size='lg'
              />
              <div className='is-margin-bottom-4'>
                <h3>{data.registration.title}</h3>
              </div>
              <p>{data.registration.hint}</p>
              <div className='columns is-centered'>
                <div className='column is-full-mobile is-10-tablet is-6-desktop'>
                  <iframe
                    id='fluentform'
                    width='100%'
                    height='100%'
                    loading='lazy'
                    style={{ minHeight: '400px', width: '100%' }}
                    frameBorder='0'
                    onLoad={(event) => {
                      event.target.style.height =
                        event.target.contentWindow.document.body.scrollHeight +
                        'px'
                    }}
                    src={iframeUrl}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {data.challenge.testimonials && (
        <>
          <div className='hero'>
            <div className='hero-body'>
              <div className='container content'>
                <div className='has-text-centered is-margin-bottom-6'>
                  <h3>{data.challenge.testimonials.title}</h3>
                </div>
                {data.challenge.testimonials.testimonials.map(
                  (testimony, index) => (
                    <div key={testimony.title} className='columns is-centered'>
                      <div className='column is-6-widescreen is-8-desktop is-10-tablet is-12-mobile'>
                        <div
                          className={clsx(
                            'columns is-flex-direction-column is-mobile is-align-items-center',
                            { 'direction-column-reverse': testimony.isEnglish }
                          )}
                        >
                          <div className='column is-align-self-flex-start'>
                            <FontAwesomeIcon icon={faQuoteRight} size='lg' />
                          </div>
                          <div
                            className='column is-padding-top-0 is-padding-bottom-0'
                            dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                          >
                            <h4 className='has-text-gold-black is-margin-bottom-0'>
                              {testimony.title}
                            </h4>
                          </div>
                          <div className='column is-align-self-flex-end'>
                            <FontAwesomeIcon icon={faQuoteLeft} size='lg' />
                          </div>
                        </div>
                        <div
                          className={clsx('columns is-align-items-start', {
                            'direction-row-reverse': testimony.isEnglish
                          })}
                        >
                          <div className='column is-narrow'>
                            <p className='has-text-centered has-text-weight-bold'>
                              {testimony.name}
                            </p>
                          </div>
                          <div className='column'>
                            <p
                              className='is-margin-bottom-0'
                              dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                            >
                              {testimony.text}
                            </p>
                          </div>
                        </div>
                        {index <
                          data.challenge.testimonials.testimonials.length -
                            1 && <hr />}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className='hero'>
            <div className='hero-body'>
              <div className='container'>
                <div className='has-text-centered'>
                  <a
                    href={`${pathname}#registration`}
                    className='button is-nude is-medium'
                  >
                    {data.callToAction}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-mobile is-multiline is-centered'>
              <div className='column content is-margin-bottom-0 is-full-mobile is-10-tablet is-6-desktop'>
                <h3>{data.about.title}</h3>
                <p>{data.about.description}</p>
              </div>
              <div className='column is-4-fullhd is-5-widescreen is-half-tablet is-10-mobile is-offset-2-fullhd is-offset-1-widescreen is-order-3-touch'>
                <GatsbyImage
                  image={getImage(survival)}
                  title={data.about.mainImage.title}
                  alt={data.about.mainImage.alt}
                  className='live-image'
                  style={{
                    maxWidth: getImage(survival).width,
                    margin: '0 auto' // used to center the image
                  }}
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {data.about.mainImage.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container'>
            <div className='has-text-centered'>
              <a
                href={`${pathname}#registration`}
                className='button is-nude is-medium'
              >
                {data.callToAction}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const InspirationalChallenge = ({
  pageContext: data,
  location: { pathname, search }
}) => {
  const images = useStaticQuery(InspirationalChallengeQuery)
  return (
    <Layout>
      <Seo
        metadata={data.challenge.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.hero),
          alt: data.challenge.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: data.headerAcademy, path: '/academy/' },
            {
              name: data.header,
              path: `/academy/challenges/${data.challenge.name.replaceAll(
                ' ',
                '-'
              )}`
            }
          ]
        }}
      />
      <InspirationalChallengeTemplate
        pathname={pathname}
        search={search}
        data={data}
        images={images}
      />
    </Layout>
  )
}

export default InspirationalChallenge
